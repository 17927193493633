import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { addListingToFavorites, removeListingFromFavorites } from '../../ducks/user.duck';
import { SearchMap, ModalInMobile, LayoutWrapperFooter, Footer, Page } from '../../components';
import { TopbarContainer } from '../../containers';

import { searchMapListings, setActiveListing, sendEnquiry } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      currentUser,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      sendEnquiryInProgress,
      sendEnquirySuccess,
      sendEnquiryError,
      onSendEnquiry,
      addToFavorites,
      removeFromFavorites,
      params = {},
    } = this.props;
    const jobRole = params['jobRole'];
    const jobRoleOptions = config.custom.jobRoles;
    const jobRoleOption =
      jobRole ? jobRoleOptions.find( option => option.key === jobRole ) : null;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    let { title, description, schema } = createSearchResultSchema(listings, address, intl);

    if( jobRoleOption ){
      title = `${jobRoleOption.label} Mentors | Career Navig8r`;
      description = `Career Navig8r have ${jobRoleOption.label} mentors to help guide you through and progress in your career. Find a mentor today and start your journey.`;
    }

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    const isIT = urlQueryParams?.pub_category === 'information_technology';
    const isHR = urlQueryParams?.pub_category === 'recruitment_and_hr';
    const isFinance = urlQueryParams?.pub_category === 'accountancy_banking_and_finance';

    const isItManager = !!location && location?.pathname?.indexOf('it-manager-informationtechnology') !== -1;
    const isMarketingManager = !!location && location?.pathname?.indexOf('marketing-manager-marketingandcommunications') !== -1;
    const isComplianceManager = !!location && location?.pathname?.indexOf('compliance-manager-consultingandstrategy') !== -1;
    const isDataScientist = !!location && location?.pathname?.indexOf('data-scientist-informationtechnology') !== -1;
    const isFacilitiesManager = !!location && location?.pathname?.indexOf('facilities-manager-administrationandofficesupport') !== -1;
    const isCustomerServiceRepresentative = !!location && location?.pathname?.indexOf('customer-service-representative-callcenterandcustomerservice') !== -1;
    const isAccountingClerk = !!location && location?.pathname?.indexOf('accounting-clerk-accounting') !== -1;
    const isStaffAccountant = !!location && location?.pathname?.indexOf('staff-accountant-accounting') !== -1;
    const isItDirector = !!location && location?.pathname?.indexOf('it-director-informationtechnology') !== -1;
    const isSeniorAccountant = !!location && location?.pathname?.indexOf('senior-accountant-accounting') !== -1;
    const isAccountsManager = !!location && location?.pathname?.indexOf('accounts-manager-accounting') !== -1;
    const isChiefFinancialOfficer = !!location && location?.pathname?.indexOf('chief-financial-officer-bankingandfinancialservices') !== -1;
    const isItSupportSpecialist = !!location && location?.pathname?.indexOf('it-support-specialist-informationtechnology') !== -1;

    let metaDescription = description

    if(isIT) metaDescription = description
    else if (isHR) metaDescription = "Are you looking to grow and excel in your career in the HR industry? Sign up with Career Navig8r and find an HR mentor based on your job role now!"
    else if (isFinance) metaDescription = "Are you looking for the best mentors to help you advance your finance career? Sign up with Career Navig8r for job role-specific finance mentoring now!"
    else if (isItManager) metaDescription = "Looking to enhance your managerial skills in the IT industry? Find your ideal IT manager mentor by signing up for Career Navig8r’s mentoring course today."
    else if (isMarketingManager) metaDescription = "Looking to advance your career as a marketing manager? Find your marketing manager mentor by signing up for Career Navig8r’s online course."
    else if (isComplianceManager) metaDescription = "Need expert career guidance on how to excel in a compliance manager role? Sign up with Career Navig8r to find your compliance manager mentor today."
    else if (isDataScientist) metaDescription = "Are you looking for career guidance as a current or aspiring data scientist? Sign up with Career Navig8r for effective data scientist mentoring today."
    else if (isFacilitiesManager) metaDescription = "Looking to grow in your career as a facilities manager? Sign up with Career Navig8r to find your ideal facilities manager mentor online."
    else if (isCustomerServiceRepresentative) metaDescription = "Want the best tips on how to be a customer service representative? To find your ideal customer service mentor online, sign up with Career Navig8r today."
    else if (isAccountingClerk) metaDescription = "Are you looking to improve your job skills as an accounting clerk? Find your ideal accounting clerk mentor by signing up with Career Navig8r today."
    else if (isStaffAccountant) metaDescription = "Are you a staff accountant looking for career guidance? To find your ideal staff accountant mentor, sign up for Career Navig8r’s mentorship course today."
    else if (isItDirector) metaDescription = "Looking to enhance your job performance and growth as an IT director? Sign up with Career Navig8r to find your ideal IT director mentor today."
    else if (isSeniorAccountant) metaDescription = "Are you looking to advance your career as a senior accountant? Sign up with Career Navig8r to find your ideal senior accountant mentor today."
    else if (isAccountsManager) metaDescription = "Are you looking to improve your career skills as an accounts manager? Find the best accounts manager mentor by signing up with Career Navig8r today."
    else if (isChiefFinancialOfficer) metaDescription = "Want to improve your job skills and excel in your career as a CFO? Sign up with Career Navig8r and find your chief financial officer mentor today."
    else if (isItSupportSpecialist) metaDescription = "Need the expert guidance of an IT support specialist mentor to advance in your career? Sign up with Career Navig8r to find your job-specific career mentor now."
  
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={metaDescription}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <MainPanel
            urlQueryParams={validQueryParams}
            currentUser={currentUser}
            listings={listings}
            jobRoleOption={jobRoleOption}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            history={history}
            intl={intl}
            sendEnquiryInProgress={sendEnquiryInProgress}
            sendEnquirySuccess={sendEnquirySuccess}
            sendEnquiryError={sendEnquiryError}
            onSendEnquiry={onSendEnquiry}
            addToFavorites={addToFavorites}
            removeFromFavorites={removeFromFavorites}
            location={location}
          />
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper}>
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={mapListings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile>
        </div>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    sendEnquiryInProgress,
    sendEnquirySuccess,
    sendEnquiryError,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    currentUser,
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    sendEnquiryInProgress,
    sendEnquirySuccess,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSendEnquiry: (email, mentoringType) => dispatch(sendEnquiry(email, mentoringType)),
  addToFavorites: listingId => dispatch( addListingToFavorites( listingId )),
  removeFromFavorites: listingId => dispatch( removeListingFromFavorites( listingId )),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
